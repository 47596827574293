body,
* {
  margin: 0;
  padding: 0;
}

/* Under construction  */
.underConstruction{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.underConstruction h3{
margin-top: 10px;
}




.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff; /* White background with no opacity */
}

.loading-button {
  border: none; /* Remove border */
  cursor: not-allowed; /* Cursor not allowed when button is disabled */
  background: transparent; /* Transparent background for the button */
}

.spinner {
  animation: spin 1s linear infinite;
  height: 40px;
  width: 40px;
  color: #ff69b4; /* Pink color */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background: #F4F3EE;
  color: #000;
}

button {
  color: #000;
  border-color: #000;
}

.text-black {
  color: #000;
}

.item-describe-box.decription-line-fixed textarea+p {
  margin-bottom: 0.8rem;
}

.item-describe-box.decription-line-fixed textarea {
  margin-bottom: 0.7rem;
}

.item-describe-box.decription-line-fixed textarea::placeholder {
  font-size: 0.65rem;
}

textarea.match-description::placeholder,
textarea.know-more-description::placeholder {
  font-size: 0.65rem;
}

section.select-plan-section p.para span {
  text-decoration: underline;
  text-underline-offset: 0.1rem;
  display: block;
  margin-top: 0.2rem;
  line-height: 1rem;
}

.item-describe-box.decription-line-fixed textarea::placeholder {
  font-size: 1rem;
}

textarea.match-description::placeholder,
textarea.know-more-description::placeholder {
  font-size: 1rem;
}

li {
  list-style: none;
}

@font-face {
  font-family: 'halyard-medium';
  src: url(../fonts/halyard/Halyard-Micro-Medium.ttf);
}

@font-face {
  font-family: 'halyard-light';
  src: url(../fonts/halyard/Halyard-Micro-Light.ttf);
}

@font-face {
  font-family: 'halyard-regular';
  src: url(../fonts/halyard/Halyard-Text-Regular.ttf);
}

@font-face {
  font-family: 'instrument-medium';
  src: url(../fonts/instrument/InstrumentSans-Medium.ttf);
}

@font-face {
  font-family: 'instrument-regular';
  src: url(../fonts/instrument/InstrumentSans-Regular.ttf);
}

h1 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin: 0;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}

.container {
  max-width: 1920px;
  margin: auto;
}

.padding-x-all {
  padding: 0 6.8rem;
}

.sec-com-padding {
  padding: 6.5rem 0;
}

.relative {
  position: relative;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.mb-p5 {
  margin-bottom: 0.5rem;
}

.mt-2-8 {
  /* margin-top: 2.8rem; */
  margin-top: 1.5rem;
}

.know-more-box {
  padding: 5rem 0;
}

/* pincode  */

.pincode-section::after {
  content: '';
  position: absolute;
  background: url(../img/pincode-section-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 0%;
  top: 0%;
  width: 50%;
  height: 100%;
  z-index: -1;
}

.pincode-box::after {
  content: '';
  position: absolute;
  background: url(../img/pincode-box-after.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 6%;
  top: 8%;
  width: 11%;
  height: 25%;
  z-index: -1;
}

.pincode-box::before {
  content: '';
  position: absolute;
  background: url(../img/pincode-box-before.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 4%;
  bottom: 5%;
  width: 15%;
  height: 30%;
  z-index: -1;
}

.pincode-box h1::after {
  content: '';
  position: absolute;
  background: url(../img/pincode-heading.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 23%;
  top: -23%;
  width: 4rem;
  height: 3rem;
  z-index: -1;
}

.pincode-box.sec-com-padding {
  padding: 9rem 0;
}

ul.pincode-usp-listing li img {
  object-fit: contain;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.1rem;
}

ul.pincode-usp-listing li {
  align-items: center;
  padding: 0 0.6rem;
  border-right: 1px solid #1B1C1C;
}

ul.pincode-usp-listing li:first-child {
  border-left: 1px solid #1B1C1C;
}

ul.pincode-usp-listing p {
  font-family: 'halyard-light';
  line-height: 0.9rem;
  letter-spacing: -0.06rem;
  font-size: 0.65rem;
}

ul.pincode-usp-listing {
  margin: 2.5rem 0;
}

form.pincode-form {
  margin-bottom: 0.8rem;
  margin-top: 4.5rem;
}

form.pincode-form input {
  font-size: 0.9rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid;
  padding: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
  width: 43%;
  margin-right: 1rem;
  font-family: 'instrument-regular';
  font-weight: 600;
  color: #000;
}

form.pincode-form input::placeholder {
  color: #000;
}



.pincode-form-btn {
  border: none;
  background: #e2e31a;
  text-transform: uppercase;
  font-family: 'halyard-medium';
  font-size: 0.9rem;
  padding: 0.6rem 2.1rem;
  border-radius: 4rem;
  cursor: pointer;
}

p.form-down-text {
  font-family: 'instrument-regular';
  font-weight: 400;
  font-size: 0.7rem;
  color: #4F4F4F;
  letter-spacing: -0.01rem;
}


/* item select  */

.item-selected,
ul.items-wears-listing li button.item-selected,
.item-selected,
ul.items-wears-listing li button:hover {
  background-color: #F3F608;
  border-color: #F3F608;
  /* font-weight: 600; */
}

.show-box {
  background: #fff;
  padding: 1.5rem 1.2rem;
  border-radius: 0.7rem;
  width: 20%;
  height: max-content;
  position: relative;
  cursor: pointer;
  transition: all 0s;
}

.show-box::after {
  content: '';
  position: absolute;
  background: url(../img/minus.png);
  background-repeat: no-repeat;
  background-size: contain;
  right: 6%;
  top: 4%;
  width: 1.4rem;
  height: 1.4rem;
}

.show-box.open::after {
  background: url(../img/plus.png);
  background-repeat: no-repeat;
  background-size: contain;
  top: 35%;
}

.show-box.open {
  height: 2.4rem;
  overflow: hidden;
  padding: 1rem 1.2rem;
  transition: all 0s;
}

p.show-box-para {
  font-family: 'instrument-regular';
  font-size: 0.65rem;
  letter-spacing: -0.04rem;
  color: #A8A8A8;
}

h4.show-box-answer {
  font-family: 'halyard-light';
  letter-spacing: -0.1rem;
  margin: 0.3rem 0;
}

.show-box-list {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #CECECE;
}

/* .show-box-last-list {
  padding-bottom: 3rem;
} */

.same-bg-section::after {
  content: '';
  position: absolute;
  background: url(../img/other-section-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: -1%;
  top: 0%;
  width: 39%;
  height: 100%;
  z-index: -1;
}

.same-bg-section::before {
  content: '';
  position: absolute;
  background: url(../img/section-before-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 0;
  top: 0%;
  width: 9%;
  height: 18%;
  z-index: -1;
}

.item-pad-box {
  padding: 4.3rem 0;
}

.item-select-box {
  width: 57%;
}

h2 {
  font-family: 'halyard-medium';
  font-weight: 100;
  line-height: 1.7rem;
  margin-bottom: 0.75rem;
  font-size: 1.45rem;
  letter-spacing: -0.1rem;
}

p.para {
  font-family: 'instrument-regular';
  font-size: 0.72rem;
  color: #808080;
}

.wears-box {
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.wears-box h5 {
  text-transform: uppercase;
  font-family: 'halyard-light';
  font-weight: 600;
  letter-spacing: -0.05rem;
  font-size: 0.7rem;
  margin-bottom: 0.7rem
}

ul.items-wears-listing li {
  margin: 0.2rem 0.15rem;
}

ul.items-wears-listing {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

ul.items-wears-listing li button {
  background: transparent;
  border: 1px solid;
  padding: 0.25rem 0.7rem;
  border-radius: 2rem;
  font-family: 'instrument-regular';
  font-size: 0.6rem;
  cursor: pointer;
}

.item-describe-box textarea {
  background: transparent;
  padding: 1rem 1rem;
  border-radius: 0.8rem;
  font-family: 'instrument-regular';
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
  width: 94%;
  height: 4rem;
}

.item-describe-box textarea::placeholder {
  color: #A8A8A8;
}

h5.describe-heading {
  font-family: 'halyard-light';
  font-weight: 600;
  letter-spacing: -0.03rem;
  background: #F4F3EE;
  padding: 0.1rem 0.3rem;
  margin-bottom: -0.6rem;
  z-index: 2;
  position: relative;
  width: max-content;
  margin-left: 1rem;
  font-size: 0.7rem;
}

h5.describe-heading+p {
  margin-bottom: 0.5rem;
}

button.section-main-btn img,
button.section-main-btn1 img {
  width: 1rem;
  margin-left: 0.7rem;
}

button.section-main-btn {
  background: #000 !important;
  color: #fff;
  border: none;
  padding: 0.6rem 0.8rem !important;
  border-radius: 0.5rem !important;
  font-family: 'halyard-light';
  letter-spacing: -0.02rem;
  width: 10rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.8rem;
}

button.buttonpayment {
  background: #000 !important;
  color: #fff;
  border: none;
  padding: 0.6rem 0.8rem !important;
  border-radius: 0.5rem !important;
  font-family: 'halyard-light';
  letter-spacing: -0.02rem;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.8rem;
  margin-top: 1rem;
  color: white;
}

.custompaymentformbox{
  padding: 0;
  display: flex;
}

button.section-main-btn1 {
  background: #0000005a !important;
  color: #fff;
  border: none;
  padding: 0.6rem 0.8rem !important;
  border-radius: 0.5rem !important;
  font-family: 'halyard-light';
  letter-spacing: -0.02rem;
  width: 10rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.8rem;
}


/* plan section  */

.select-plan-box {
  padding: 4rem 0;
  padding-bottom: 3rem;
}

.select-plan-inner-box button {
  width: 92%;
  margin: 0.8rem 0;
  background: #fff;
  border: none;
  padding: 0.8rem 1rem;
  padding-bottom: 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.select-plan-inner-box {
  width: 57%;
}

.select-plan-inner-box button .text-box {
  text-align: start;
  width: 70%;
}

.select-plan-inner-box button .icon-box {
  width: 13%;
}

.select-plan-inner-box button .icon-box img {
  width: 4.2rem;
  margin: auto;
  object-fit: contain;
}

.select-plan-inner-box button .check-box {
  width: 6%;
}

.select-plan-inner-box button .check-box img {
  background: #D9D9D9;
  padding: 0.4rem;
  border-radius: 2rem;
  width: 0.8rem;
  height: 0.8rem;
  object-fit: contain;
}

.select-plan-inner-box button.plan-selected .check-box img {
  background: #000;
}

.select-plan-inner-box button .text-box h3 {
  font-family: 'halyard-medium';
  letter-spacing: -0.05rem;
  font-size: 1.2rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 0.8rem;
  font-weight: 100;
}

.select-plan-inner-box button .text-box h4 {
  font-size: 0.9rem;
  font-family: 'instrument-regular';
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}

p.unsure-btn {
  font-family: 'instrument-regular';
  font-size: 0.6rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  cursor: pointer;
  width: max-content;
  margin-bottom: 2rem;
}

/* alteration   */
.icon-main-box .icon-box {
  background: #F4F3EE;
  margin: 0rem 0.5rem;
  margin-bottom: 1rem;
  padding: 0.8rem 1.4rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
}

.icon-main-box .icon-box:last-child {
  margin-bottom: 0;
}

.icon-main-box .icon-box h6 {
  font-family: 'halyard-light';
  font-size: 0.7rem;
  letter-spacing: -0.03rem;
}

.alteration-select-box {
  margin-bottom: 4.5rem;
  padding: 2rem 1.5rem;
}

.alteration-box {
  padding: 5.6rem 0;
}

.icon-main-box {
  padding-right: 2rem;
  margin-right: 1rem;
  border-right: 1px solid #D9D9D9;
  width: 22%;
}

.icon-main-box .icon-box img {
  width: 2.8rem;
  object-fit: contain;
}

.alteration-select-box .text-box {
  width: 66.5%;
}

.alteration-select-box .text-box h4 {
  font-family: 'halyard-light';
  line-height: 1rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  letter-spacing: -0.05rem;
}

.alteration-select-box ul.items-wears-listing {
  margin-top: 1rem;
  width: 100%;
  max-height: 8.5rem;
  overflow-y: scroll;
}

.icon-main-box .icon-box:hover,
.icon-main-box .icon-box.item-selected {
  background-color: #F3F608;
  cursor: pointer;
}

/* perfect fit  */

.perfect-fit-box {
  margin: 1.5rem 0;
  margin-bottom: 2.5rem;
}

.perfect-fit-box .tabs-box {
  width: 15%;
}

.tab-content-box {
  width: 82%;
}

.perfect-fit-box .tabs-box button {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.perfect-fit-box .tabs-box button:nth-child(2) {
  margin: 0.8rem 0;
}

.perfect-fit-box .tabs-box button img {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.3rem;
}

.perfect-fit-box .tabs-box button h6 {
  font-family: 'instrument-medium';
  font-weight: 100;
  font-size: 0.65rem;
}

.perfect-fit-box .tabs-box button:hover,
.perfect-fit-box .tabs-box button.item-selected {
  background-color: #e2e31a;
}

.video-box video,
.video-box iframe {
  width: 100%;
  height: 16rem;
  object-fit: cover;
  cursor: pointer;
}

.perfect-fit-box .video-box {
  width: 40%;
}

.perfect-fit-box .text-box {
  width: 56%;
}

.play-btn {
  position: absolute;
  top: 41%;
  left: 35%;
}

.play-btn img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  cursor: pointer;
}

.perfect-fit-box .text-box h4 {
  font-family: 'instrument-medium';
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.perfect-fit-box .text-box p.para {
  margin-bottom: 0.5rem;
}

a.popup-text,
a.popup-text button {
  font-family: 'instrument-medium';
  font-size: 0.55rem;
  color: #464646;
  letter-spacing: -0.01rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  background: transparent;
  border: none;
}

.perfect-fit-box .text-box h5 {
  margin-top: 1rem;
}

.perfect-fit-box .text-box h5 span {
  background: #26DB38;
  padding: 0.05rem 0.15rem;
  border-radius: 0.2rem;
  margin-right: 0.3rem;
}

.perfect-fit-box .text-box h5 span img {
  width: 0.6rem;
  height: 0.4rem;
  object-fit: contain;
}

.perfect-fit-box .text-box h5 {
  margin-top: 1rem;
  font-family: 'instrument-medium';
  font-weight: 100;
  font-size: 0.7rem;
  letter-spacing: -0.02rem;
  display: flex;
  align-items: center;
}

.perfect-fit-box .text-box h5 input {
  margin-right: 0.3rem;
}

textarea.match-description {
  height: 6rem;
  width: 13rem;
  margin: 0;
}

.content-box-three .text-box {
  /* border-right: 1px solid #D9D9D9; */
  padding-right: 1.5rem;
  margin-right: 1.2rem;
}

.content-box-three .example-note-box {
  width: 33.5%;
  display: none;
}

.example-note-box h4 {
  font-family: 'instrument-medium';
  font-weight: 400;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.example-note-box .para {
  font-style: italic;
  font-size: 0.78rem;
}

.content-box-three .text-box textarea.match-description {
  height: 5.6rem;
}

/* .show-box-last-list h4+p {
  font-size: 0.9rem;
} */


/* know more  */


textarea.know-more-description {
  height: 7rem;
  margin-bottom: 1rem;
  width: 80%;
}

.mb-1 {
  margin-bottom: 1rem;
}

/* item detail  */
.show-detail-box {
  width: 35%;
}

.sub-total-box {
  width: 38%;
}

h4.add-new-text {
  font-size: 1rem;
  font-family: 'halyard-light';
  letter-spacing: -0.08rem;
  font-weight: 200;
}

.add-new-item-box {
  background: #fff;
  padding: 1rem 1.2rem;
  border-radius: 0.7rem;
  margin-bottom: 1rem;
  width: 100%;
  border: none;
  text-align: start;
  cursor: pointer;
}

.add-new-item-box::after {
  content: '';
  position: absolute;
  background: url(../img/add.png);
  background-repeat: no-repeat;
  background-size: contain;
  right: 6%;
  top: 39%;
  width: 0.8rem;
  height: 0.8rem;
}

.price-detail-box {
  background: #fff;
  padding: 1rem 1.2rem;
  border-radius: 0.7rem;
}

.item-name-price {
  padding-bottom: 1rem;
  border-bottom: 2px dotted;
  margin-bottom: 1rem;
}

/* .item-name-price::after{
  content: '';
  position: absolute;
  background: url(../img/down-arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  right: 0%;
  top: 15%;
  width: 0.9rem;
  height: 0.9rem;
} */
.add-another-service-box h5 {
  font-family: 'halyard-light';
  font-size: 0.65rem;
  font-weight: 100;
  display: flex;
  align-items: center;
}

.add-another-service-box h5 img {
  width: 0.6rem;
  margin-right: 0.5rem;
}

.item-name-text img {
  width: 0.6rem;
  margin-left: 0.6rem;
  cursor: pointer;
}

.add-another-service-box button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.name-price-text {
  width: 48%;
  font-family: 'halyard-medium';
  font-size: 1rem;
  line-height: 1.1rem;
  letter-spacing: -0.05rem;
  margin-bottom: 0.3rem;
  font-weight: 200;

}

ul.sub-total li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

ul.sub-total li {
  border-bottom: 1px solid #00000038;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

ul.sub-total {
  background: #fff;
  margin-bottom: 1rem;
  padding: 1rem 1.2rem;
  border-radius: 0.7rem;
  max-height: 16rem;
  overflow-y: scroll;
}

ul.sub-total li h4 {
  font-size: 0.9rem;
  font-family: 'halyard-light';
  letter-spacing: -0.08rem;
}

.fxry-promise-text {
  font-size: 1.1rem;
  font-family: 'halyard-light';
  letter-spacing: -0.08rem;
  font-weight: 200;
}

ul.fxry-usp-listing {
  margin: 1rem 0;
  justify-content: space-between;
}

.frxy-promise {
  background: #fff;
  padding: 1.3rem 1.2rem;
  border-radius: 0.7rem;
}

ul.fxry-usp-listing li .text-box {
  text-align: center;
}

ul.fxry-usp-listing li .img-box {
  text-align: center;
}

ul.fxry-usp-listing li {
  width: 23%;
  padding: 0 0.9rem;
}

button.checkout-btn {
  width: 13rem !important;
  margin-top: 2.5rem;
}

.item-detail-section::after {
  background: url(../img/checkout-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  width: 60%;
}


/* checkout  */

.checkout-left-box {
  width: 40%;
}

.promo-code-box {
  background: #fff;
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-radius: 0.7rem;
  font-family: 'halyard-light';
}

.promo-code-box .promo-code {
  padding: 0 1.2rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #EAE9E6;
}

.promo-code-box .checkout-sub-total {
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid #EAE9E6;
}

.promo-code-box .checkout-total {
  padding: 0 1.2rem;
  padding-top: 0.8rem;
}

button.promo-code-btn {
  padding: 0.4rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 2rem;
  background: #E2E21A;
  font-size: 0.8rem;
  font-family: 'halyard-medium';
  margin-left: -1.5rem;
  cursor: pointer;
}

form.promo-code input {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  border: 1px solid #C2C2C2;
  width: 69%;
}

.promo-code-box .checkout-sub-total h6 {
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-family: 'instrument-regular';
  font-weight: 200;
}

.promo-code-box .checkout-total h5 {
  font-size: 0.9rem;
}

.user-detail-head-box div h6 {
  background: #000;
  width: max-content;
  color: #fff;
  padding: 0.35rem 0.4rem;
  border-radius: 1rem;
  margin: auto;
  margin-bottom: 0.4rem;
  font-size: 0.5rem;
}

.user-detail-head-box div h5 {
  font-size: 0.6rem;
  line-height: 0.7rem;
}

.user-detail-head-box div {
  background: #fff;
  padding: 0.8rem 0.9rem;
  border-radius: 0.4rem;
  width: 23.5%;
  font-family: 'halyard-light';
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.user-detail-head-box div:hover {
  background: #E2E21A;
}

.user-detail-head-box {
  margin-bottom: 1.2rem;
}

.user-detail-main-box {
  background: #Fff;
  padding: 1.5rem 1rem;
  border-radius: 0.7rem;
  height: 19.5rem;
  overflow-y: scroll;
}

section.checkout-section .item-detail-box {
  padding: 5.1rem 0;
}

label.label-on-line {
  font-family: 'halyard-light';
  letter-spacing: -0.05rem;
  font-size: 0.65rem;
  background: #fff;
  margin-left: 1rem;
  z-index: 1;
  position: relative;
  padding: 0.3rem;
  font-weight: 600;
}

.user-detail-main-box input {
  width: 97%;
  border: 1px solid #00000059;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  padding: 0.7rem 0.3rem;
  margin-bottom: 0.7rem;
  /* margin-top: -0.9rem; */
}

.user-detail-main-box .field-box input {
  margin-top: -0.9rem;
}

.user-detail-main-box h4 {
  font-family: 'halyard-light';
  letter-spacing: -0.04rem;
  margin-bottom: 0.5rem;
}

.click-to-change span img {
  width: 0.6rem;
}

.click-to-change span {
  padding: 0.2rem 0.25rem;
  border-radius: 0.1rem;
  margin-right: 0.3rem;
  height: 0.7rem;
  cursor: pointer;
}

p.para.click-to-change {
  display: flex;
  font-size: 0.6rem;
  width: 90%;
  margin-bottom: 0.5rem
}

label.label {
  font-family: 'halyard-light';
  letter-spacing: -0.05rem;
  font-size: 0.65rem;
  background: #fff;
  font-weight: 600;
}

label.label+input {
  margin-top: 0.3rem;
}

p.smallest-para {
  font-family: 'instrument-regular';
  font-size: 0.8rem;
  color: #717171;
}

.form-section-border {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #000000;
  margin-bottom: 1.3rem;
}

.user-detail-main-box input::placeholder {
  font-size: 0.6rem;
}

.underlined-text {
  font-size: 0.7rem;
  font-family: 'instrument-regular';
}

.react-calendar {
  margin-top: 1.2rem;
  background: #F6F6F6;
  border: none;
  border-radius: 0.4rem;
  width: 100%;
  font-family: 'instrument-regular';
  padding: 1rem;
}

span.react-calendar__navigation__label__labelText {
  font-size: 1rem;
  font-weight: 700;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #E2E21A;
}

.react-calendar__tile--now {
  background: grey;
  color: #000;
  /* border: 1px solid #000 !important; */
}

.react-calendar__navigation button {
  font-size: 1rem;
}

abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: #3C3C4399;
  font-size: 0.6rem;
}

.react-calendar__tile {
  border-radius: 3rem;
  padding: 0.6rem 0.3rem;
}

.yes-no-box div {
  font-size: 0.7rem;
  font-family: 'halyard-light';
  font-weight: 600;
  padding: 0.2rem 0.9rem;
  border-radius: 1rem;
  border: transparent;
  margin-top: 0.7rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.form-question {
  font-family: 'halyard-light';
  font-size: 0.65rem;
  letter-spacing: -0.03rem;
  margin-bottom: 0.7rem;
}

.yes-no-box {
  margin-bottom: 1rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.card-paypal-box div {
  width: 41.5%;
  background: #F6F6F6;
  padding: 0.7rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  cursor: pointer;
}

.card-paypal-box h5 {
  font-family: 'instrument-regular';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.card-paypal-box div img {
  width: 1rem;
  margin-right: 0.5rem;
}

.user-detail-main-box .field-box.half-box {
  width: 47%;
}

.payment-info-box input {
  margin-bottom: 0.4rem;
}

.card-paypal-box {
  margin-bottom: 0.8rem;
  margin-top: 0.7rem;
}

.payment-info-box button.section-main-btn {
  width: 100% !important;
  margin-top: 1rem;
}

.two-input-box {
  width: 97%;
}

/* thankyou screen  */

section.thankyou-section {
  background: url(../img/thankyou-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

section.thankyou-section .padding-x-all {
  padding: 0 4rem;
}

section.thankyou-section .sec-com-padding {
  padding: 5rem 0;
}

.thankyou-section h1 {
  font-size: 1.9rem;
  line-height: 2.1rem;
  font-family: 'halyard-light';
  margin-bottom: 0.6rem;
}

p.thankyou-para {
  font-family: 'halyard-light';
  letter-spacing: -0.02rem;
  font-size: 0.7rem;
  font-weight: 600;
}

section.thankyou-section button.section-main-btn {
  width: max-content !important;
  margin: 1rem 0;
}

.collection-name-box {
  margin: 1.3rem 0;
  border-top: 1px solid;
  width: 50%;
  border-bottom: 1px solid;
  padding: 1.2rem 0;
}

.collection-name-box h5 {
  font-family: 'halyard-light';
  font-size: 0.85rem;
}

.collection-name-box div:first-child {
  border-right: 1px solid;
  margin-right: 1.4em;
  padding-right: 1.4rem;
}

.thankyou-section h2 {
  font-size: 1.1rem;
  font-family: 'halyard-light';
  font-weight: 600;
  margin-bottom: 0.4rem;
}

p.problem-text a {
  color: #000;
  text-underline-offset: 0.15rem;
}

p.problem-text {
  font-family: 'instrument-regular';
  font-size: 0.85rem;
  margin-top: 0.8em;
}

ul.prepare-listing h6 {
  font-family: 'halyard-light';
  font-size: 0.7rem;
}

ul.prepare-listing h6 span {
  background: #000;
  color: #fff;
  padding: 0.3rem 0.55rem;
  display: inline-block;
  width: 0.4rem;
  height: 0.9rem;
  border-radius: 1.1rem;
  font-size: 0.6rem;
  margin-right: 0.4rem;
}

ul.prepare-listing li {
  margin: 0.25rem 0;
}

.checkout-left-box .frxy-promise {
  display: none;
}


.item-table {
  border-collapse: collapse;
  width: 100%;
}

.item-table td,
.item-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 0.55rem;
}

.item-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.item-table tr:hover {
  background-color: #dddddd;
}

.item-table p {
  margin: 0;
}


/* css correction started  */
.go2072408551 {
  font-size: 1rem;
  font-family: 'instrument-regular';
  width: max-content;
}

p.unsure-btn button {
  background: transparent;
  padding: 0;
  width: max-content;
  border-bottom: 1px solid;
  border-radius: 0;
}

.service-job-detail-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-job-detail-box p {
  width: 80%;
}

.service-job-detail-box div {
  width: 18%;
}

.service-job-detail-box div h4 {
  margin: 0;
  margin-top: -0.2rem;
}

h5.describe-heading.adjust-sub-head {
  padding: 0;
  margin: 0;
  margin-bottom: 0.2rem;
}

.item-name-price table.item-table tr td p {
  font-family: 'instrument-regular';
  font-size: 0.8rem;
}

.item-name-price table.item-table tr td:first-child p {
  font-family: 'instrument-medium';
}

.item-name-price table.item-table {
  margin-top: 0.5rem;
}

.scroll-wrapper::-webkit-scrollbar {
  width: 0.4rem;
}

.scroll-wrapper {
  padding-right: 0.5rem;
}

.scroll-wrapper::-webkit-scrollbar-track {
  background: rgba(255, 166, 0, 0);
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 3rem;
}

.pincode-form-btn+p {
  margin-top: 0.6rem !important;
}

@media all and (min-width: 1920px) {
  html {
    font-size: 25px;
  }

  section {
    width: 1920px;
    margin: auto;
  }
}

@media all and (max-width: 1920px) {
  html {
    font-size: 1.4vw;
  }
}

@media all and (min-width: 900px) {
  .desktop-none {
    display: none !important;
  }

  .sec-com-padding {
    padding: 0 !important;
    /* height: 40rem; */
    height: 100vh;
  }

  .show-detail-box.scroll-wrapper {
    max-height: 80vh !important;
  }

  ul.sub-total {
    max-height: 35vh !important;
  }

  .pincode-box.sec-com-padding {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p.unsure-btn {
    margin-bottom: 1rem;
  }

  form.pincode-form {
    height: 5rem;
  }

  .tab-content-box {
    height: 18rem;
  }

  .perfect-fit-box .text-box+p {
    margin-top: 0.3rem;
  }

  section.thankyou-section .sec-com-padding {
    padding: 5rem 0 !important;
    height: 29rem;
  }

  a.popup-text button {
    cursor: pointer;
  }

  .content-box-three .text-box textarea.match-description::placeholder,
  textarea.match-description::placeholder {
    font-size: 0.71rem;
  }
}

@media all and (max-width: 900px) {
  .mobile-none {
    display: none !important;
  }

  html {
    font-size: 3.2vw;
  }

  br {
    display: none;
  }

  .padding-x-all {
    padding: 0 1.5rem;
  }

  .pincode-box.sec-com-padding {
    padding: 5rem 0;
  }

  .pincode-box::before {
    bottom: -21%;
  }

  .show-box {
    width: calc(100% - 4rem);
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  .items-box {
    flex-wrap: wrap;
    /* flex-direction: column; */
  }

  .item-select-box {
    width: 100%;
  }

  .item-describe-box textarea {
    width: 93%;
  }

  .sec-com-padding {
    padding: 2rem 0;
  }

  .select-plan-inner-box {
    width: 100%;
  }

  .same-bg-section::after {
    left: 0%;
    top: 0%;
    width: 100%;
    height: 34%;
    background: url(../img/mobile-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .pincode-section::after {
    left: 0%;
    top: 0%;
    width: 100%;
    height: 34%;
    background: url(../img/mobile-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .pincode-box h1::after,
  .pincode-box::after {
    display: none;
  }

  .pincode-box h1 {
    margin-top: 7rem;
    font-size: 2.6rem;
  }

  ul.pincode-usp-listing p {
    font-family: 'halyard-light';
    line-height: 0.9rem;
    letter-spacing: -0.06rem;
    font-size: 1.25rem;
  }

  ul.pincode-usp-listing li img {
    object-fit: contain;
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 0.8rem;
  }

  ul.pincode-usp-listing li {
    border: none !important;
    margin: 0;
  }

  p.form-down-text {
    font-size: 0.9rem;
  }

  form.pincode-form input {
    font-size: 0.9rem;
    width: 54%;
  }

  .alteration-select-box {
    margin-bottom: 2.5rem;
    padding: 2rem 1.2rem;
  }

  .same-bg-section::before {
    display: none;
  }

  .pincode-box::after {
    right: 6%;
    top: 13%;
    width: 15%;
    height: 25%;
  }


  p.show-box-para {
    font-size: 0.75rem;
  }

  h4.show-box-answer {
    font-size: 1.2rem;
  }

  .show-box-last-list {
    padding-bottom: 1rem;
  }

  .show-box-last-list h4+p {
    font-size: 1rem;
  }

  h4.show-box-answer {
    margin: 0.5rem 0;
  }

  h2 {
    font-size: 1.85rem;
    margin-bottom: 1rem;
    line-height: 2rem;
  }

  .item-select-box h2+p {
    font-size: 0.9rem;
  }

  .perfect-fit-box .tabs-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .perfect-fit-box {
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
  }

  .perfect-fit-box .tabs-box button {
    display: flex;
    align-items: center;
    width: 32%;
    padding: 0.8rem 1rem;
  }

  .perfect-fit-box .tabs-box button:nth-child(2) {
    margin: 0;
  }

  .perfect-fit-box .tabs-box button img {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0;
    margin-right: 0.3rem;
  }

  .perfect-fit-box .tabs-box button h6 {
    font-size: 0.9rem;
  }

  .tab-content-box,
  .perfect-fit-box .text-box,
  .perfect-fit-box .video-box {
    width: 100%;
  }

  .common-box {
    flex-wrap: wrap;
  }

  .perfect-fit-box .video-box h4 {
    font-size: 1.2rem;
    font-family: 'instrument-medium';
    margin-bottom: 1rem;
    font-weight: 400;
  }

  p.para {
    font-size: 0.9rem;
  }

  .perfect-fit-box .text-box p.para {
    margin-bottom: 0.7rem;
    margin-top: 0.9rem;
  }

  a.popup-text,
  a.popup-text button {
    font-size: 0.85rem;
  }

  .perfect-fit-box .text-box h5 {
    font-size: 1rem;
    margin-bottom: -0.7rem;
    letter-spacing: 0.05rem;
  }

  button.section-main-btn,
  button.section-main-btn1 {
    width: 100% !important;
    padding: 0.7rem 1.2rem !important;
    font-size: 1.15rem;
  }

  .perfect-fit-box .text-box h5 span {
    padding: 0.3rem 0.3rem 0.05rem 0.2rem;
  }

  .perfect-fit-box .text-box h5 span img {
    width: 0.9rem;
    height: 0.8rem
  }

  .play-btn {
    top: 50%;
    left: 45%;
  }

  .content-box-three .text-box {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }

  .perfect-fit-box .text-box h4 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .example-note-box h4 {
    font-size: 1.2rem;
  }

  .example-note-box .para {
    font-size: 0.95rem;
  }

  button.section-main-btn img,
  button.section-main-btn1 img {
    margin-left: 0.7rem;
  }

  .video-box video {
    height: 13rem;
  }

  .play-btn img {
    width: 2.8rem;
    height: 2.8rem;
  }

  .video-popup {
    position: fixed;
    background: #000000d9;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .video-popup-inner video {
    height: 44rem;
    width: 26rem;
  }

  button.close-btn {
    position: absolute;
    top: 3%;
    right: 3%;
    background: transparent;
    border: none;
  }

  img.pop-up-close-icon {
    width: 2rem;
  }

  .item-describe-box textarea {
    font-size: 1.2rem;
    height: 6rem;
  }

  .icon-main-box {
    padding-right: 0;
    margin-right: 0;
    width: 100%;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .icon-main-box .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    margin: 0;
    padding: 0.6rem 1.2rem;
  }

  .icon-main-box .icon-box img {
    width: 1.7rem;
    margin-right: 0.7rem;
  }

  .icon-main-box .icon-box h6 {
    font-size: 1.1rem;
  }

  .alteration-select-box .text-box {
    width: 100%;
    margin-top: 1rem;
  }

  ul.items-wears-listing li button {
    padding: 0.25rem 0.8rem;
    font-size: 0.85rem;
  }

  .icon-main-box h4 {
    font-family: 'halyard-light';
    line-height: 1.5rem;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
    letter-spacing: -0.05rem;
  }

  ul.items-wears-listing li {
    margin: 0.3rem 0.2rem;
  }

  .alteration-select-box ul.items-wears-listing {
    width: 100%;
  }

  h5.describe-heading {
    font-size: 1rem;
    /* margin-bottom: -0.8rem; */
  }

  .content-box-three .example-note-box {
    width: 100%;
  }

  .select-plan-inner-box button {
    width: 100%;
    padding: 1.8rem 1rem;
    padding-bottom: 2rem;
  }

  .select-plan-inner-box button .text-box h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .select-plan-inner-box button .text-box h4 {
    font-size: 1.2rem;
    line-height: 1.3rem;
    margin-bottom: 0.8rem;
  }

  .select-plan-inner-box button .text-box h4+p {
    font-size: 0.75rem;
  }

  .select-plan-inner-box button .check-box {
    width: 5%;
    margin-top: -9.2rem;
  }

  .select-plan-inner-box button .text-box {
    width: 68%;
  }

  .select-plan-inner-box button .icon-box {
    width: 20%;
  }

  .select-plan-inner-box button .icon-box img {
    width: 4.7rem;
    margin-bottom: 0.5rem;
  }

  .select-plan-inner-box button .check-box img {
    width: 1rem;
    height: 1rem;
  }

  p.unsure-btn {
    font-size: 0.85rem;
  }

  p.unsure-btn button {
    font-size: 0.85rem;
    margin-bottom: 0;
  }

  h4.show-box-answer {
    margin-top: 0rem;
  }

  .show-box.open {
    height: 2.6rem;
    overflow: hidden;
    padding: 0.6rem 2rem;
    width: calc(100% - 4rem);
    margin: auto;
    margin-bottom: 7.5rem;
  }

  .show-box::after {
    right: 5%;
    top: 6%;
    width: 2.2rem;
    height: 2.1rem;
  }

  .show-box.open::after {
    top: 21%
  }

  .sub-total-box {
    width: 100%;
  }

  .show-detail-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .add-new-item-box {
    margin-bottom: 6.5rem;
    padding: 1rem 1.5rem;
  }

  h4.add-new-text {
    font-size: 1.1rem;
  }

  .add-new-item-box::after {
    height: 1rem;
    width: 1rem;
  }

  .name-price-text {
    font-size: 1.45rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
    display: flex;
  }

  .item-name-text img {
    width: 0.8rem;
  }

  .add-another-service-box h5 {
    font-size: 1rem;
  }

  .add-another-service-box h5 img {
    width: 0.8rem;
  }

  ul.sub-total li h4 {
    font-size: 1.2rem;
  }

  .price-detail-box {
    margin-bottom: 0.7rem;
    margin-top: 0.7rem !important;
    padding: 1.8rem 1.5rem;
  }

  ul.sub-total {
    padding: 1.8rem 1.5rem;
  }

  .fxry-promise-text {
    font-size: 1.4rem;
    font-weight: 600;
  }

  ul.fxry-usp-listing li {
    width: 100%;
    padding: 0.15rem 0rem;
    display: flex;
  }

  button.section-main-btn img,
  button.section-main-btn1 img {
    width: 1.5rem;
  }

  .checkout-left-box {
    width: 100%;
  }

  .user-detail-head-box {
    margin: 0.5rem 0rem;
    /* position: sticky; */
    /* top: 0%; */
    background: #f4f3ee;
    padding: 0rem;
    /* z-index: 10; */
    /* flex-wrap: nowrap; */
    /* overflow: scroll; */
  }

  .user-detail-main-box {
    height: 100%;
    padding: 1.8rem 1.5rem;
  }

  .user-detail-head-box div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    min-width: 11.8rem !important;
    margin-bottom: 0.9rem;
  }

  .user-detail-head-box div h6 {
    margin: 0;
    margin-right: 0.5rem;
    font-size: 0.7rem;
    padding: 0.4rem 0.5rem;
  }

  .user-detail-head-box div h5 {
    font-size: 1rem;
    line-height: 1rem;
  }

  form.promo-code input {
    font-size: 1rem;
    width: 72.5%;
  }

  button.promo-code-btn {
    font-size: 1rem;
  }

  .promo-code-box {
    padding: 1.8rem 0;
  }

  section.checkout-section .item-detail-box {
    padding: 2rem 0;
  }

  .promo-code-box .checkout-sub-total h6 {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }

  .promo-code-box .checkout-total h5 {
    font-size: 1.2rem;
  }

  .user-detail-main-box h4 {
    font-size: 1.4rem;
  }

  .user-detail-main-box input {
    font-size: 1.1rem;
    padding: 0.9rem 0.3rem;
    margin-bottom: 0.9rem;
  }

  .user-detail-main-box input::placeholder {
    font-size: 0.85rem;
  }

  label.label-on-line {
    font-size: 1rem;
  }

  .user-detail-main-box .field-box input {
    margin-top: -0.8rem;
  }

  label.label {
    font-size: 1rem;
  }

  p.para.click-to-change {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .click-to-change span img {
    width: 0.8rem;
  }

  .click-to-change span {
    padding: 0.3rem 0.35rem;
    margin-right: 0.5rem;
    height: 0.9rem;
  }

  p.smallest-para {
    font-size: 0.8rem;
  }

  .underlined-text {
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 0.8rem;
  }

  .react-calendar__navigation button {
    font-size: 1.5rem;
  }

  .react-calendar__tile {
    padding: 1rem 0rem;
    font-size: 1.1rem;
  }

  .form-question {
    font-size: 0.95rem;
  }

  .yes-no-box div {
    font-size: 1rem;
    padding: 0.4rem 1.3rem;
    border-radius: 2rem;
  }

  .card-paypal-box h5 {
    font-size: 0.95rem;
  }

  .card-paypal-box div img {
    width: 1.1rem;
    margin-right: 0.85rem;
  }

  .card-paypal-box {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  section.thankyou-section .sec-com-padding {
    padding: 4.5rem 0;
    padding-bottom: 14rem;
  }

  section.thankyou-section .padding-x-all {
    padding: 0 1.5rem;
  }

  .thankyou-section h1 {
    font-size: 2.2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  p.thankyou-para {
    font-size: 0.95rem;
  }

  .collection-name-box {
    width: 100%;
    margin: 1.8rem 0;
  }

  .thankyou-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  section.thankyou-section button.section-main-btn {
    margin: 1.3rem 0;
  }

  ul.prepare-listing h6 {
    font-size: 1rem;
  }

  ul.prepare-listing h6 span {
    font-size: 0.9rem;
    width: 0.8rem;
    height: 1.4rem;
    padding: 0.2rem 0.5rem;
    text-align: center;
  }

  ul.prepare-listing li {
    margin: 0.85rem 0;
  }

  p.problem-text {
    font-size: 1rem;
  }

  ul.prepare-listing {
    margin: 1.5rem 0;
  }

  .collection-name-box h5 {
    font-size: 1.05rem;
  }

  .pincode-form-btn {
    font-size: 1rem;
    padding: 0.9rem 3rem;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
  }

  form.pincode-form input {
    font-size: 1rem;
    width: 97%;
  }

  /* mobile fix   */
  .pincode-box ul.pincode-usp-listing li {
    margin: 0.2rem 0;
  }

  .sub-total-box ul.fxry-usp-listing li {
    width: 29%;
    padding: 0.15rem 0rem;
    display: block;
  }

  .sub-total-box ul.pincode-usp-listing li img {
    margin-right: 0;
    margin-bottom: 0.3rem;
    margin-bottom: 0.3rem;
  }

  ul.pincode-usp-listing p {
    line-height: 1.3rem;
    font-size: 1.05rem;
  }

  .service-job-detail-box p {
    font-size: 1rem;
  }

  .service-job-detail-box div h4 {
    margin-top: -1.5rem;
  }

  .service-job-detail-box div {
    width: 11%;
  }

  .alteration-select-box ul.items-wears-listing {
    max-height: 13.5rem;
  }

  .show-detail-box.scroll-wrapper {
    max-height: max-content !important;
    padding: 0;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }

  .name-price-text {
    justify-content: end;
  }

  h3.name-price-text.item-name-text {
    justify-content: start;
  }

  .item-name-price table.item-table tr td p {
    font-size: 1rem;
  }

  .item-table td,
  .item-table th {
    padding: 0.75rem;
  }

  .item-name-price table.item-table {
    margin-top: 0.8rem;
  }

  .item-name-price {
    padding-bottom: 1.5rem;
    margin-bottom: 0.7rem;
  }

  .show-detail-box.scroll-wrapper {
    flex-direction: column;
  }

  ul.sub-total {
    max-height: 30rem;
  }


  .error-message {
    color: red;
    margin-top: 10px;
  }



  form.pincode-form {
    height: 11rem;
  }

  .perfect-fit-box .text-box+p {
    margin-top: 1.2rem;
  }

  /* .tab-content-box .content-box-one {
  height: 25rem;
}
.tab-content-box .content-box-three {
  height: 18rem;
}
.tab-content-box .content-box-two {
  height: 34rem;
} */

/* Stripe Checkout Card Details Form CSS */
/* .App {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
} */

#payment-form {
  width: 15rem;
  min-width: 500px;
  align-self: flex-start;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px; */
  padding: 40px;
  margin-top: auto;
  margin-bottom: auto;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.buttonpayment {
  background: #E2E21A;
  font-family: Arial, sans-serif;
  color: black;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.buttonpayment:hover {
  filter: contrast(115%);
}

.buttonpayment:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #0055DE;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #0055DE;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

/* dynamic payment methods annotation */
#dpm-annotation {
  align-self: center;
  color: #353A44;
  width: 30vw;
  min-width: 500px;
  line-height: 20px;
  margin-bottom: 20px;
}

#dpm-integration-checker {
  display: inline;
  color: #533AFD;
}

/* Payment status page */
#payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  width: 30vw;
  min-width: 500px;
  min-height: 380px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
  margin-top: auto;
  margin-bottom: auto;
}

#status-icon {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

#details-table {
  overflow-x: auto;
  width: 100%;
}

table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
}
table tbody tr:first-child td {
  border-top: 1px solid #E6E6E6; /* Top border */
  padding-top: 10px;
}
table tbody tr:last-child td {
  border-bottom: 1px solid #E6E6E6; /* Bottom border */
}
td {
  padding-bottom: 10px;
}

.TableContent {
  text-align: right;
  color: #6D6E78;
}

.TableLabel {
  font-weight: 600;
  color: #30313D;
}

#view-details {
  color: #0055DE;
}

#retry-button {
  text-align: center;
  background: #0055DE;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimation {
  to {
      opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  #payment-form, #dpm-annotation, #payment-status {
    width: 80vw;
    min-width: initial;
  }
}


}